#faq {
    width: 600px;
    padding: 10px;
    margin: auto;
    margin-top: 20px;
}

#faq a {
    color: blue;
    word-wrap: break-word;
}

#faq h2 {
    text-align: center;
}

#faq > ul {
    padding: 0;
}

#faq > ul > li {
    list-style-type: none;
    margin-bottom: 10px;
}

#faq .faq-title {
    position: relative;
    cursor: pointer;
    padding: 10px;
    background-color: rgb(40, 40, 50);
    border-radius: 5px;
}

#faq .faq-title > i {
    z-index: 0;
    position: absolute;
    right: 20px;
    font-size: 20px;
}

#faq .faq-text {
    display: none;
    margin: 20px;
    font-size: 14px;
    font-family: "Verdana";
    font-weight: 400;
    color: white;
}

#disclaimer {
    font-size: 12px;
    font-family: "Verdana";
    font-weight: 400;
    color: lightgray;
}

@media only screen and (max-width: 600px) {
    #faq .faq-title > i {
        display: none;
    }
}