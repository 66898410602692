#header a {
    text-decoration: none;
    color: white;
}

#header a:hover {
    text-decoration: none;
    color: #ffaa00;
}

#header li {
    list-style-type: none;
}

#header {
    height: 80px;
    background-color: rgb(30,30,40);
    box-shadow: 0 5px 20px #000;
}

#header > div {
    display: flex;
    max-width: 1000px;
    margin: auto;
    padding-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
}

#header-right {
    margin-left: auto;
}

#header-right #wallet-state {
    font-size: 14px;
    color: lightgray;
    display: flex;
    flex-direction: column;
    max-width: 200px;
    padding: 10px;
}

#header-right #wallet-state > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#header-menu {
    display: flex;
    gap: 20px;
}
#header img {
    width: 256px;
    height: 48px;
}

@media (max-width: 768px) { /* Tablet and smaller devices */
    #header img {
        width: 160px;
        height: 48px;
    }
}

@media (max-width: 480px) { /* Mobile devices */
    #header img {
        width: 120px;
        height: 48px;
    }
}
@media only screen and (max-width: 600px) {
    #header {
        overflow: hidden;
    }

    #header > div > a  {
        width: 50%;
    }
    
    #header-right .button {
        font-size: 16px;
        min-width: auto;
        padding: 5px;    
        margin-left: 10px;
    }
}