#stake-list {
    width: 600px;
    margin: auto;
}

#stake-list > h2 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;
}

.active-stake {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    box-shadow: 0 0px 10px #ffaa00;
    padding: 15px;
    border-radius: 5px;
}

.active-stake .top {
    margin-bottom: 10px;
}

.active-stake span {
    margin-left: 5px;
}

#stake-list-summary {
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 30px;
    box-shadow: 0 0px 20px #ffaa00;
}

#claim #pending-reward {
    margin-top: 10px;
    margin-bottom: 10px;
}

#claim #pending-reward > span {
    margin-left: 5px;
}

#claim #claim-result {
    color: lime;
    min-height: 20px;
    margin-top: 10px;
}

@media only screen and (max-width: 600px) {
    .active-stake .button {
        min-width: auto;
    }
}