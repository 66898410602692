.stake-input {
    margin-bottom: 20px;
}

.stake-input .stake-input-title {
    margin-bottom: 5px;
}

#stake {
    padding: 20px;
    width: 500px;
    margin: auto;
    box-shadow: 0 0px 20px #ffaa00;
    border-radius: 2%;
}

#stake-result, #unstake-result {
    color: lime;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 20px;
    text-align: center;
}

#unstake-result {
    margin-top: 0px;
    margin-bottom: 15px;
}

#stake-top {
    margin-bottom: 40px;
    text-align: center;
}

#stake-button-wrap {
    text-align: center;
}

.slider-input {
    display: flex;
    justify-content: center;
    height: 25px;
    margin-bottom: 5px;
}

.slidecontainer {
    width: 50%;
    margin-left: 10px;
    margin-right: 10px;
  }
  
.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 5px;
    background: #ffaa00;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}
  
.slider:hover {
    opacity: 1;
}
  
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #ffaa00;
    border-radius: 50%;
    cursor: pointer;
}

.slider-label {
    width: 25%;
    color: lightgray;
    line-height: 25px;
}

.slider-label.left {
    text-align: right;
}

.slider-label.right {
    cursor: pointer;
    text-align: left;
}

.slider-value {
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    .slidecontainer {
        width: 70%;
    }
}