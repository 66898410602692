#statistics {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    margin-bottom: 40px;
}

#statistics > div {
    width: 170px;
    text-align: center;
}

#statistics .stats-value {
    font-size: 26px;
    color: #ffaa00;
}
